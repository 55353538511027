$mobile-max: 992px;
$desktop-min: 993px;

$brand-red: #A10204;
$brand-teal: #79ada0;
$brand-grey-light: #F5F5F5;
$brand-grey-dark: #4c4c4e;

// Container
.container {
  width: 90%!important;
  max-width: 1800px!important;
  margin: 0 auto!important;
}



// Buttons
a.wsite-button {
  span.wsite-button-inner {
    background-color: $brand-red;
    border: 0;
    text-transform: uppercase;
    font-weight: 700;
  }
  &:hover {
    span.wsite-button-inner {
      background-color: $brand-teal;
      border: 0;

    }
  }
}
a.phone {
  position: absolute;
  top: 15px!important;
  bottom: auto!important;
  left: 15px!important;
  right: auto!important;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 3px black;
  z-index: 999;
  padding-left: 20px;
  background-image: url('//external.breezeweb.ca/assets/fbccranbrook/images/icon_phone.svg');
  background-position: left center;
  background-size: 15px;
  background-repeat: no-repeat;
  &:hover {
    color: white;
  }

}

span.service {
  position: absolute;
  top: 30px!important;
  bottom: auto!important;
  left: 15px!important;
  right: auto!important;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 1px 1px 3px black;
  z-index: 999;
  @media (min-width: $desktop-min) {
    top: 30px!important;
    bottom: auto!important;
    left: auto!important;
    right: 15px!important;
  }
  &:nth-of-type(2) {
    top: 60px!important ;
    @media (min-width: $desktop-min) {

    }
  }
}

body.nav-open {
  span.service,
  a.phone {
    display: none!important;
  }
}


div.dusk-header {
  @media (max-width: $mobile-max) {
    position: absolute!important;
    background:  transparent!important;
  }
  div.nav-wrap {
    @media (max-width: $mobile-max) {
      background-color: transparent!important;
    }
  }
}

.wsite-header-section:before {
  display: none;
}





// Nav

div.nav {
  text-align: center;
  ul.wsite-menu-default {
    float: none;
    margin: auto;
    width: 100%;
    &>li.wsite-menu-item-wrap {
      float: none;
      @media (max-width: $mobile-max) {
        // text-align: left;
      }
      &>a {
        color: $brand-grey-dark;
        text-transform: lowercase;
        font-weight: bold;
        font-size: 18px;
      }
      &>a + span::before {
        color: $brand-grey-dark;
      }
      &:hover {
        &>a {
          color: $brand-red!important;
        }
      }
      ul {
        li {
          a {
            @media (max-width: $mobile-max) {
              font-size: 15px!important;
              line-height: 0.9;
            }
          }
        }
      }
    }
    &>li#active {
      a.wsite-menu-item {
        color: $brand-red!important;
      }
    }
    &>li.wsite-menu-item-wrap {
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        &>a {
          &::after {
            @media (min-width: $desktop-min) {
              display: inline-block;
              content: " ";
              margin-left: 4px;
              border: 5px solid transparent;
              border-top-color: #000;
              vertical-align: -2px;
            }

          }
        }
      }
    }
  }
}

ul.wsite-menu {
  background-color: $brand-grey-light!important;
  box-shadow: px 5px 1px 0px rgba(0,0,0,0.05)!important;
  li {
    a.wsite-menu-subitem {
      color: $brand-red!important;
      font-size: 18px!important;
      font-weight: bold!important;
    }
  }
}

.dusk-header .hamburger span,
.dusk-header .hamburger span:before,
.dusk-header .hamburger span:after {
  background: $brand-red!important;
}

body.nav-open {
  @media (max-width: $mobile-max) {
    .dusk-header .hamburger span:before,
    .dusk-header .hamburger span:after {
      background: $brand-red!important;
    }
    .dusk-header .hamburger span {
      background-color: transparent!important;
    }
  }
}

// Footer
div.footer-wrap {
  border-top: 0;
  .wsite-social-item {
    color: white!important;
  }
  &>.container {
    // width: 100%;
    div.footer {
      .footer-container {
        max-width: 1260px!important;
        margin: auto!important;
      }
      .wsite-footer {
        width: 100%;

        // &>div {
        //   // container width here
        //   max-width: 1800px;
        //   width: 90%;
        //   margin: auto;
        //   background-color: #f2f2f2;
        //   &>div {
        //     max-width: 1024px;
        //     width: 100%;
        //     margin: auto;
        //     &>div .colored-box-content {
        //
        //     }
        //   }
        // }
        // &>div:first-of-type {
        //   &>div {
        //     &>div .colored-box-content {
        //       background-color: transparent!important;
        //     }
        //   }
        // }
      }
    }

  }
}
